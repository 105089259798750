<template>
  <section>
    <header>
      <h1>{{ $t('generalCalendar.title', { patient: user.id !== null ? user.username : '' }) }}</h1>
    </header>

    <calendar-validation />

    <calendar-table @display-update="onDisplayUpdateDialog" :patientId="user.id"/>

    <update-calendar-dialog :options.sync="updateDialog"/>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarDesktop',

  components: {
    CalendarTable: () => import('@/components/GeneralCalendar/CalendarTable'),
    UpdateCalendarDialog: () => import('@/components/GeneralCalendar/UpdateCalendarDialog'),
    CalendarValidation: () => import('@/components/GeneralCalendar/CalendarValidation'),
  },

  data: () => ({
    updateDialog: {
      display: false,
      milestoneUpdated: null,
    },
  }),

  computed: {
    ...mapGetters(['user']),
  },

  methods: {
    onDisplayUpdateDialog (milestoneUpdated) {
      this.updateDialog.milestoneUpdated = { ...milestoneUpdated }
      this.updateDialog.display = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/backgroundPage";

section {
  > header {
    display: grid;
    grid-template-columns: 1fr 9fr 1fr;
    grid-template-areas: "empty title action";
    align-items: center;
    justify-items: center;

    h1 {
      grid-area: title;
    }

    button.v-btn {
      grid-area: action;

      i.v-icon {
        font-size: 35px;
      }
    }
  }
}
</style>
